<template>
  <div>
    <!-- 移动端 -->
    <div class="mobile">
      <div>
        <el-calendar>
          <template #date-cell="{ data }">
            <div>
              <template v-for="item in calendarData">
                <template v-if="item.dateCalendar.substring(0, 10) === data.day">
                  <div
                    @click="oDay(item)"
                    :key="item.dateCalendar"
                    :class="getClass(item)"
                  >
                    {{ data.day.slice(-2) }}
                  </div>
                </template>
              </template>
            </div>
          </template>
        </el-calendar>
      </div>

      <div
        :class="['main_box']"
        :style="{ 'pointer-events': mainBox_disable ? 'none' : 'auto' }"
      >
        <div v-if="!mobileSubmitDisabled" class="header" style="margin-bottom: 10px">
          <div>
            <el-button
              type="warning"
              :icon="Upload"
              @click="publishFn('mobile')"
              circle
            />
            <el-button
              type="success"
              :icon="Download"
              @click="unPublishFn('mobile')"
              circle
            />
            <el-button
              :disabled="mobileSubmitDisabled"
              @click="addItemMobole"
              type="primary"
              :icon="Plus"
              circle
            />
          </div>
          <div class="header_detail" v-if="dateDetail">
            {{ dateDetail.dateCalendar.slice(0, 10) }}
            <span v-if="dateDetailH">
              (GMT{{ dateDetailH > 0 ? "+" + dateDetailH : dateDetailH }})
            </span>
            &nbsp;{{ dateDetail.apptNum }}/{{ dateDetail.apptMax }}
          </div>
        </div>
        <el-row :gutter="24" class="row" v-for="(item, index) in oneDayList" :key="index">
          <el-col :span="7">
            <div class="col">
              <el-time-select
                v-model="item.timeStart"
                :max-time="item.timeEnd"
                class="select"
                placeholder="Start"
                start="00:00"
                step="01:00"
                end="24:00"
                :editable="false"
                :disabled="item.published || mobileSubmitDisabled"
                clear-icon
              /></div
          ></el-col>
          <el-col :span="7"
            ><div class="col">
              <el-time-select
                v-model="item.timeEnd"
                class="select"
                :min-time="item.timeStart"
                placeholder="End"
                start="00:00"
                step="01:00"
                end="24:00"
                :editable="false"
                clear-icon
                :disabled="item.published || mobileSubmitDisabled || item.timeStart == ''"
              /></div
          ></el-col>
          <el-col :span="5"
            ><div style="margin-left: 15px" class="col">
              <el-tag v-if="item.published" type="success">Published</el-tag>
              <el-tag @click="singlePublishFn(index)" v-else-if="!item.published_ui"
                >Draft</el-tag
              >
              <el-tag
                @click="singlePublishFn(index)"
                v-else-if="item.published_ui"
                type="primary"
                >Published</el-tag
              >

              <!-- <el-select v-model="item.published" class="select" placeholder="Max">
                <el-option
                  v-for="item in publishedList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select> -->
            </div></el-col
          >
          <el-col :span="4"
            ><div style="text-align: right">
              <el-button
                @click="deleteItemMobole(item.uId)"
                type="danger"
                :icon="Delete"
                :disabled="item.published || mobileSubmitDisabled"
                circle
              /></div
          ></el-col>
        </el-row>

        <div style="text-align: center">
          <el-button
            type="primary"
            :disabled="mobileSubmitDisabled"
            round
            @click="oneDaySubmit"
            >Submit</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ElMessage, ElMessageBox } from "element-plus";
import {
  getOnlinePeriod,
  setOnlinePeriod,
  deleteonlinePeriod,
  getOnlinePeriodPage,
} from "@/api/detailPage.js";
import {
  Check,
  Delete,
  Edit,
  Message,
  Search,
  Star,
  Upload,
  Download,
  Plus,
} from "@element-plus/icons-vue";
import { ElButton, ElDialog } from "element-plus";
import { CircleCloseFilled } from "@element-plus/icons-vue";
import { reactive, ref, onMounted, watch, nextTick, computed } from "vue";
import { getPrice, updataPrice } from "@/api/center.js";
import { useStore } from "vuex";
import {
  checkTimeConflicts,
  addOneMonthAndSetToFirstDay,
  nowDay,
  selectDay,
} from "@/components/timeTransform/index.js";
const store = useStore();
const mainBox_disable = ref(false);
//默认是当前时间 如果选中日期会随之改变
const nowTime = ref(new Date());
//时区偏移量
const tZoneOffset = computed(() => {
  let time = new Date().getTimezoneOffset();
  return 0 - time / 60;
});
const expertId = computed(() => {
  return JSON.parse(store.state.userInfo).id;
});

const queryParams = ref({
  pageNum: "1",
  pageSize: "10",
  expertId: expertId.value,
  timeZone: tZoneOffset.value,
});
const state = reactive({
  total: 100,
});
//拿当天
const toDay = computed(() => {
  return nowDay();
});
const dialogSlort = ref([]);

const dateDetail = ref("");


const isDateSclectShow = ref(true);


//以当前天数为标准的头尾日期
const endOfCalendar = ref([]);
//手机端按钮状态
const mobileSubmitDisabled = ref(false);

const myCalendar = ref();
//手机端的日历展示
const calendarData = ref([]);


const oneDayList = ref([
  {
    expertId: expertId.value,
    uId: 1,
    date: nowDay(),
    timeStart: "",
    timeEnd: "",
    published: false,
    published_ui: false,
    timeZone: tZoneOffset.value,
  },
]);

const allArr = ref([]);



//反转单个状态 手机
const singlePublishFn = (index) => {
  oneDayList.value[index].published_ui = !oneDayList.value[index].published_ui;
  console.log(oneDayList.value, "oneDayList.value");
};

//初始化当天的可预约时间
const toDayItem = async (arr, time) => {
  let data = {
    dateStart: arr[0],
    dateEnd: arr[1],
    expertId: expertId.value,
    timeZone: tZoneOffset.value,
  };
  let res = await getOnlinePeriod(data);
  if (res.code == "200") {
    if (Array.isArray(res.data) && res.data) {
      console.log(res.data, "res.data");
      calendarData.value = res.data;

      let arr = calendarData.value.filter((item) => item.dateCalendar == time);
      oDay(arr[0]);
    }
  }
};
//详情时区显示
const dateDetailH = ref("");

//是否继续的规则
const isResume = () => {
  if (oneDayList.value.length > 0 && oneDayList.value[0].timeStart !== "") {
    let flag = oneDayList.value.every((item) => item.id);
    if (flag) {
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
};

//手机端获取单天数据
const getItem = (item) => {
  dateDetailH.value = "";
  console.log(item, "iitt");
  dateDetail.value = item;
  mainBox_disable.value = false;
  let str = new Date(item.dateCalendar);
  nowTime.value = str;
  getCalendarDates(str);
  getOnlinePeriodList(endOfCalendar.value);
  resOneDayList(item.dateCalendar.slice(0, 10));
  console.log(item, "null");

  if (item.scheduleList && item.scheduleList.length > 0) {
    dateDetailH.value = dateDetail.value.scheduleList[0].timeZone;
    oneDayList.value = item.scheduleList.map((info, index) => {
      return {
        ...info,
        timeStart: info.timeStart.slice(0, 5),
        timeEnd: info.timeEnd.slice(0, 5),
        id: item.scheduleList[index].id ? item.scheduleList[index].id : null,
        uId: index + 1,
        published_ui: info.published,
      };
    });
    console.log(oneDayList.value, " oneDayList.value2");
  }

  if (nowDay() < item.dateCalendar || nowDay() == item.dateCalendar) {
    mobileSubmitDisabled.value = false;
    console.log(item, "item");
  } else {
    mobileSubmitDisabled.value = true;
    mainBox_disable.value = true;
  }
};

//点击日历单天
const oDay = (item) => {
  let isResume1 = isResume();
  if (isResume1) {
    ElMessageBox.confirm(
      "The last record will not be saved, do you want to continue?",
      "Warning",
      {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }
    ).then(() => {
      getItem(item);
    });
  } else {
    getItem(item);
  }
};

const publishFn = (type) => {
  if (type == "mobile") {
    if (oneDayList.value.length > 0) {
      oneDayList.value = oneDayList.value.map((item) => {
        return {
          ...item,
          published_ui: true,
        };
      });
      return;
    }
  }
  if (type == "pc") {
    if (allArr.value.length > 0) {
      allArr.value = allArr.value.map((item) => {
        console.log(item, "item");
        return {
          dataList: item.dataList.map((innerItem) => {
            return {
              ...innerItem,
              published_ui: true,
            };
          }),
        };
      });
    }
  }
};

const unPublishFn = (type) => {
  if (type == "mobile") {
    if (oneDayList.value.length > 0) {
      oneDayList.value = oneDayList.value.map((item) => {
        return {
          ...item,
          published_ui: false,
        };
      });
    }
  }
  if (type == "pc") {
    if (allArr.value.length > 0) {
      allArr.value = allArr.value.map((item) => {
        console.log(item, "item");
        return {
          dataList: item.dataList.map((innerItem) => {
            return {
              ...innerItem,
              published_ui: false,
            };
          }),
        };
      });
    }
  }
};


//查找日日历头尾
const getCalendarDates = (today) => {
  //获取当前日期
  // let today = new Date();
  console.log(today, "zzzz");

  //获取当月的第一天
  let firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

  //获取当月的最后一天
  let lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

  //日历通常会从周日开始，所以需要获取这个月的第一天是星期几，然后再算出日历的开始日期
  //如果第一天是周二（getDay() === 2），那日历应该从前一周的周日开始。
  let calendarStart = new Date(
    firstDayOfMonth.setDate(firstDayOfMonth.getDate() - firstDayOfMonth.getDay())
  );

  //同理，我们也需要计算出日历的结束日期
  let calendarEnd = new Date(
    lastDayOfMonth.setDate(lastDayOfMonth.getDate() + (6 - lastDayOfMonth.getDay()))
  );

  //转化日期为 YYYY-MM-DD 格式
  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }
  console.log([formatDate(calendarStart), formatDate(calendarEnd)], "zzzz");

  return (endOfCalendar.value = [formatDate(calendarStart), formatDate(calendarEnd)]);
};

const addItemMobole = () => {
  oneDayList.value.push({
    expertId: expertId.value,
    uId: oneDayList.value.length + 1,
    date: oneDayList.value[0].date,
    timeStart: "",
    timeEnd: "",
    published: false,
    timeZone: tZoneOffset.value,
    published_ui: false,
  });
};

const deleteItemMobole = (uId) => {
  const dataIndex = oneDayList.value.findIndex((item) => item.uId === uId);

  if (dataIndex !== -1) {
    let oneTime = oneDayList.value[dataIndex];
    if (oneTime.id) {
      ElMessageBox.confirm(
        "This action will delete all saved dates (excluding published dates) if you continue!",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(async () => {
        let arr = oneDayList.value.filter((item) => item.id);
        arr = arr.map((item) => {
          return {
            ...item,
            timeStart: item.timeStart + ":00",
            timeEnd: item.timeEnd + ":00",
          };
        });
        let { code } = await deleteonlinePeriod(arr);
        if (code == "200") {
          reSizeMobileToday();
          ElMessage({
            type: "success",
            message: "Successfully deleted",
          });
        }
      });
    } else {
      oneDayList.value.splice(dataIndex, 1);
    }
  }
};

//手机端 初始化当前天的时间段
const reSizeMobileToday = () => {
  getCalendarDates(nowTime.value);
  getOnlinePeriodList(endOfCalendar.value);
  toDayItem(endOfCalendar.value, dateDetail.value.dateCalendar.slice(0, 10));
};



const resOneDayList = (date) => {
  oneDayList.value = [
    {
      expertId: expertId.value,
      uId: 1,
      date,
      timeStart: "",
      timeEnd: "",
      published: false,
      published_ui: false,
      timeZone: tZoneOffset.value,
    },
  ];
};

const getDatesBetween = (startDate, endDate) => {
  const dateArray = [];
  const currentDate = new Date(startDate);
  const end = new Date(endDate);

  while (currentDate <= end) {
    dateArray.push(new Date(currentDate));
    currentDate.setDate(currentDate.getDate() + 1);
  }

  // 将日期对象转换为字符串
  return dateArray.map((date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  });
};

//GTM转标准时间
const gtmTransform = (inputDateString) => {
  const inputDate = new Date(inputDateString);

  const year = inputDate.getFullYear();
  const month = String(inputDate.getMonth() + 1).padStart(2, "0");
  const day = String(inputDate.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};




//手机端单天提交修改
const oneDaySubmit = () => {
  console.log(oneDayList.value, "oneDayList");
  let oneD = [];
  let publishedTimeZone = null;

  // 遍历一次列表，寻找第一个设置了published的item
  // 并记录它的timeZone
  oneDayList.value.forEach((item) => {
    if (!publishedTimeZone && item.published) {
      publishedTimeZone = item.timeZone;
    }
    // 如果已经找到了published的timeZone，为之后的所有项设置相同的timeZone
    if (publishedTimeZone) {
      item.timeZone = publishedTimeZone;
    }
    oneD.push(item);
  });

  console.log(oneD, "oneD");

  let isSuccess = checkTimeConflicts(oneD);
  //dateDetail.dateDetail.dateCalendar.dateCalendar
  if (isSuccess) {
    ElMessageBox.confirm(
      `<p>Are you sure you want to change the content of <span style="color:#10bd8e;">${dateDetail.value.dateCalendar.slice(
        0,
        10
      )}</span>?</p>`,
      "Tips",
      {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        dangerouslyUseHTMLString: true,
      }
    ).then(async () => {
      console.log("成功");
      let arr = [];
      arr = oneD.map((item) => {
        return {
          ...item,
          published: item.published_ui,
          timeStart: item.timeStart + ":00",
          timeEnd: item.timeEnd == "00:00" ? "24:00:00" : item.timeEnd + ":00",
        };
      });
      // console.log(arr, "arr");
      let res = await setOnlinePeriod(arr);
      if (res.code == "200") {
        mobileSubmitDisabled.value = true;
        ElMessage({
          showClose: true,
          message: "Save successfully.",
          type: "success",
        });
        reSizeMobileToday();
      }
      console.log(res, "res");
    });
    // .catch(() => {
    //   ElMessage({
    //     type: "info",
    //     message: "Modify cancel",
    //   });
    // });
  } else {
    console.log("失败");
    ElMessage({
      showClose: true,
      message: "The time period you set does not meet the specification!",
      type: "error",
    });
  }
};


//获取日历的数据 手机端使用
const getOnlinePeriodList = async (arr) => {
  let data = {
    dateStart: arr[0],
    dateEnd: arr[1],
    expertId: expertId.value,
    timeZone: tZoneOffset.value,
  };
  let res = await getOnlinePeriod(data);
  if (res.code == "200") {
    if (Array.isArray(res.data) && res.data) {
      console.log(res.data, "res.data");
      calendarData.value = res.data;
    }
  }
};


const getClass = (item) => {
  const classes = ["Calendar-div"];
  if (item.dateCalendar == dateDetail.value.dateCalendar) {
    classes.push("borderActive");
  }

  if (item.apptStatus === "APPT_NONE") {
    classes.push("color_appt_none");
  } else if (item.apptStatus === "APPT") {
    classes.push("color_appt");
  } else if (item.apptStatus === "DRAFT") {
    classes.push("color_draf");
  }

  if (item.dateCalendar.substring(0, 11) < gtmTransform(new Date())) {
    classes.push("color_hui");
  }

  return classes;
};

onMounted(() => {
  getCalendarDates(nowTime.value);
  getOnlinePeriodList(endOfCalendar.value);
  toDayItem(endOfCalendar.value, toDay.value);
  setTimeout(() => {
    let prevBtn = document.querySelectorAll(
      ".el-calendar__button-group .el-button-group>button:nth-child(1)"
    );
    console.log("prevBtn", prevBtn);
    prevBtn[prevBtn.length - 1].addEventListener("click", () => {
      console.log("上一个");
      nowTime.value = addOneMonthAndSetToFirstDay(nowTime.value, "jj");
      getCalendarDates(nowTime.value);
      console.log(endOfCalendar.value, "endOfCalendar.value");
      getOnlinePeriodList(endOfCalendar.value);
    });
    //回到当前日期
    let nowBtn = document.querySelectorAll(
      ".el-calendar__button-group .el-button-group>button:nth-child(2)"
    );
    nowBtn[nowBtn.length - 1].addEventListener("click", () => {
      console.log("当前");
      nowTime.value = new Date();
      getCalendarDates(nowTime.value);
      getOnlinePeriodList(endOfCalendar.value);
      toDayItem(endOfCalendar.value, toDay.value);
    });
    //下一个
    let nextBtn = document.querySelectorAll(
      ".el-calendar__button-group .el-button-group>button:nth-child(3)"
    );
    nextBtn[nextBtn.length - 1].addEventListener("click", () => {
      console.log("下一个");
      nowTime.value = addOneMonthAndSetToFirstDay(nowTime.value, "add");
      getCalendarDates(nowTime.value);
      getOnlinePeriodList(endOfCalendar.value);
    });
  }, 500);
});
</script>
<style lang="scss" scoped>
@media screen and (max-width: 768px) {
  .main_box {
    padding: 10px 0px 5px 15px;
    min-height: 220px;
    margin-bottom: 20px;
    border: 1px #eee solid;

    .header {
      display: flex;
      justify-content: space-between;

      .header_detail {
        line-height: 32px;
        font-weight: 700;
        margin-right: 10px;
      }
    }
  }
  .main_box_disable {
    pointer-events: none;
  }
  .el-main {
    padding: 0 !important;
  }
  :deep(.el-calendar-day) {
    height: 40px !important;
  }
  :deep(.el-calendar-day) {
    padding: 0 !important;
  }
  :deep(.video-js) {
    object-fit: cover;
  }

  :deep(.el-drawer__header) {
    display: none;
  }

  /* 修改poster图片大小 */
  video::poster {
    width: 100% !important;
    height: 100% !important;
  }
  :deep(.el-drawer__header) {
    margin-bottom: 0px;
  }
  :deep(.btt) {
    background-color: rgba(0, 0, 0, 0.65);
  }

  :deep(.vjs-poster) {
    height: 100vh;
    background-size: cover !important;
  }
  :deep(.el-select__input) {
    width: 70px !important;
  }
  :deep(.is-guttered) {
    padding: 0px 2px !important;
  }

  .weight {
    font-weight: 700;
    color: red !important;
  }

  .color_hui {
    background-color: #f5f5f5 !important;
    color: #cacaca !important;
    // border: 0.5px solid #e1e1e1;
  }
  .color_bai {
    background-color: #ffffff !important;
  }
  .color_appt {
    background: #10bd8e;
    color: #000000;
  }
  .borderActive {
    border: 1px solid blue !important;
  }
  .color_appt_none {
    background-color: #8b8b8b;
    color: #000000;
  }
  .color_draf {
    background-color: #409eff;
  }
  :deep(.el-calendar__header) {
    padding: 10px 0;
    justify-content: space-around;
  }
  .timeTable {
    border-top: 2px solid black; /* 上边框 */
    border-right: 2px solid black; /* 右边框 */
    border-left: 2px solid black; /* 左边框 */
    border-bottom: none; /* 去掉下边框 */
    border-radius: 2px;
    font-size: 18px;

    .header {
      display: flex;
      justify-content: space-between;
      font-weight: 600;
      padding: 0 10px;
      border-bottom: 2px solid black;
    }

    div {
      height: 50px;
      line-height: 50px;
      text-align: center;
    }
  }

  .make_btn {
    font-size: 17px;
    color: #fff;
    text-align: center;
    font-style: normal;
    line-height: 47.4px;
    letter-spacing: 0.03em;
    background-color: #fbcda5;
    width: 100%;
    cursor: default;
    font-weight: 700;
    margin-top: 15px;
    padding: 0 1em;
    opacity: 1;
    box-sizing: border-box;
  }
  .schedule-end {
    margin: 20px 0;
    font-size: 14px;
    font-weight: 600;
    .line {
      height: 30px;
      display: flex;
    }
    .gray {
      height: 14px;
      width: 14px;
      background-color: #8b8b8b;
      border: 1px solid #3c3935;
      margin-right: 5px;
    }
    .green {
      height: 14px;
      width: 14px;
      background-color: #10bd8e;
      border: 1px solid #3c3935;
      margin-right: 5px;
    }
  }
  .make_btn_active {
    background-color: rgb(247, 134, 51);
  }
  .calender-title {
    font-size: 14px;
    color: #0099a8;
    font-style: normal;
    text-align: left;
    line-height: 36px;
    letter-spacing: 0.03em;
    font-weight: 700;
    // float: left;
  }
  .calender-title1 {
    font-size: 13px;
    color: #333;
    font-style: normal;
    text-align: left;
    line-height: 34px;
    letter-spacing: 0.03em;
    font-weight: 400;
    clear: both;
  }
  .flex {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .weight {
    font-weight: 600;
  }
  .Calendar-div {
    height: 40px;
    // background-color: skyblue;
    line-height: 40px;
    text-align: center;
    // color: black !important;
  }
  .gou {
    position: absolute;
    bottom: 5px;
    right: 5px;
  }
  .Calendar-div1 {
    height: 40px;
    // background-color: skyblue;
    line-height: 40px;
    text-align: center;
  }
  // .Calendar-div:hover {
  //   // color: white !important;
  //   border: 1px blue solid;
  // }
  // .Calendar-div1:hover {
  //   color: white !important;
  //   background-color: #add8e6 !important;
  // }

  .is-disabled {
    pointer-events: none;
    color: #ccc;
  }
  .is-color-red {
    background-color: rgb(255, 192, 192) !important;
    color: #ccc;
  }
  .is-color-blue {
    background-color: rgb(173, 216, 230) !important;
    color: #ccc;
  }
  .is-color-yellow {
    background-color: rgb(255, 255, 192) !important;
    color: #ccc;
  }
  .is-color-gray {
    background-color: rgb(136, 136, 136) !important;
    color: #ccc;
  }
  :deep(.el-calendar-day) {
    height: 40px !important;
  }
  :deep(.el-calendar-day) {
    padding: 0 !important;
  }
  :deep(.video-js) {
    object-fit: cover;
  }

  :deep(.el-drawer__header) {
    display: none;
  }

  /* 修改poster图片大小 */
  video::poster {
    width: 100% !important;
    height: 100% !important;
  }
  :deep(.el-drawer__header) {
    margin-bottom: 0px;
  }
  :deep(.btt) {
    background-color: rgba(0, 0, 0, 0.65);
  }

  :deep(.vjs-poster) {
    height: 100vh;
    background-size: cover !important;
  }

  .pc {
    display: none;
  }
  .main {
    position: relative;
    width: 100%;
    height: 100vh; /* 设置高度为视口的高度，以覆盖整个屏幕 */
    color: #ffffff;
  }
  .video-top {
    width: 100%;
  }
  .video-container {
    width: 100%;
    height: 100%;
  }
  .videoAWS-poster {
    width: 100%;
    height: 100%;
    object-fit: cover; /* 让图片等比例缩放并填充整个容器 */
  }

  .User-top {
    position: absolute;
    top: 20px;
    left: 30px;
  }
  .chah {
    position: absolute;
    top: 20px;
    right: 30px;
  }
  .w-50 {
    position: absolute;
    bottom: 20px;
    left: 20px;
    width: 150px;
    height: 40px;
    line-height: 40px;
    color: #000000;
    background-color: yellow;
    text-align: center;
  }

  .mobleSet {
    position: absolute;
    bottom: 230px;
    right: 20px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    padding: 5px;
    background-color: rgba(0, 0, 0, 0.2);
    color: white;

    p {
      height: 40px;
      line-height: 45px;
      text-align: center;
    }
  }
  .mobleSetAfter {
    position: absolute;
    bottom: 230px;
    right: 20px;
    width: 40px;
    height: 250px;
    border-radius: 18px;
    padding: 5px;
    background-color: rgba(0, 0, 0, 0.2);
    color: white;
    text-align: center;

    p {
      height: 50px;
      line-height: 50px;
    }
  }

  .demo-image .block {
    // padding: 30px 0;
    text-align: center;
    // border-right: solid 1px var(--el-border-color);
    display: inline-block;
    width: 33%;
    box-sizing: border-box;
    vertical-align: top;
  }
  .demo-image .block:last-child {
    border-right: none;
    color: #ffffff;
  }

  .custom-gift {
    border-radius: 10%;
  }

  .mobileMainCointaner {
    background: linear-gradient(180deg, lightblue, #fff, #fff);
    position: absolute;
    // height: 100%;
    width: 100%;
    height: 100%;
    // overflow-y: auto !important;

    .breadcrumb {
      font-size: 12px;
      font-weight: 700;
      margin: 20px 10px;
    }

    .advisorCard {
      // border: 1px solid #eaeaea;
      border-radius: 20px;
      height: 100%;
      margin: 10px 10px 10px 10px;
      box-sizing: border-box;
      // overflow: hidden;

      .advisorImage {
        position: relative;
        width: 100%;

        img {
          max-height: 300px;
          object-fit: cover;
          width: 100%;
        }

        .advisorRatingContainer {
          align-items: center;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          padding: 10px;
          width: 100%;

          .advisorRating {
            align-items: center;
            background-color: #ffecbc;
            border-radius: 51px;
            font-size: 18px;
            justify-content: flex-start;
            padding: 3px 15px;
            position: absolute;
            top: 10px;
          }

          .favorite {
            position: absolute;
            right: 10px;
            top: 5px;
            z-index: 10;

            img {
              height: 35px;
              width: 35px;
            }
          }
        }

        .advisorNameContainer {
          background: linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.5) 50%);
          bottom: 7%;
          position: absolute;
          width: 100%;

          .advisorName {
            color: white;
            font-size: 26px;
            font-weight: 700;
            padding-left: 15px;
          }

          .advisorCategory {
            color: white;
            font-size: 18px;
            font-weight: 400;
            overflow: hidden;
            padding: 0 0 20px 15px;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }

        .advisorStatus {
          align-items: center;
          border: 4px solid white;
          border-radius: 50px;
          bottom: 0%;
          color: white;
          display: flex;
          font-size: 14px;
          font-weight: 700;
          justify-content: center;
          left: 50%;
          padding: 5px 20px;
          position: absolute;
          text-align: center;
          transform: translateX(-50%);
        }

        .advisorStatus-online {
          @extimeend .advisorStatus;
          background-color: rgb(115, 201, 64);
        }

        .advisorStatus-live {
          @extimeend .advisorStatus;
          background-color: red;
        }

        .advisorStatus-busy {
          @extimeend .advisorStatus;
          background-color: #46a6ff;
        }

        .advisorStatus-offline {
          @extimeend .advisorStatus;
          background-color: #b6b6b6;
        }
      }

      .advisorChat {
        background-color: rgba(0, 0, 0, 0.04);
        border-radius: 6px;
        color: black;
        height: 25vh;
        overflow: hidden;
        overflow-y: scroll;
        position: relative;
        width: 100%;
      }

      .buttonContainer {
        align-items: center;
        display: flex;
        justify-content: center;

        .button {
          border: 1px solid lightgray;
          height: 40px;
          line-height: 40px;
          margin: 10px;
          text-align: center;
          width: 150px;
        }

        .chatButton {
          @extimeend .button;
          background-color: yellow;
          color: black;
        }

        .startVideoButton {
          @extimeend .button;
          background-color: #ffe400;
          color: #000000;
        }
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .tagWidth {
    width: 80.19px;
    margin-left: 12px;
  }
  .bottom {
    margin-bottom: 20px !important;
  }
  .newDays {
    margin: -20px 0px 25px 55px;
  }
  .mobile {
    display: none;
  }
}

:deep(.el-dialog__footer) {
  position: absolute;
  bottom: 0;
  right: 20px;
}

:deep(.el-dialog) {
  min-height: 600px;
  max-height: 800px;
}

.list_body {
  margin-left: 5%;
  max-height: 500px;
  overflow: hidden;
  overflow-y: auto;
}

.page-list {
  margin-top: 20px;
  display: flex;
  justify-content: right;
  margin-right: 200px;
}
.ml-2 {
  margin: 0 5px;
}

.col {
  text-align: right;
  height: 32px;
  line-height: 32px;
}
.row {
  margin-bottom: 10px;
}

.item {
  display: flex;
}

.my-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.select {
  max-width: 140px;
}
.profile {
  line-height: 50px;
  border-bottom: 1px solid #c2bcbc;
  padding-left: 20px;
  color: #02758a;
}
.main-box {
  padding: 18px;
}
</style>
